<template>
  <ul class="from-our-directory__category-list">
    <li
      class="from-our-directory__category-wrapper"
      v-for="category in categories"
      :key="category.Id"
    >
      <a-fm-dir-category
        class="from-our-directory__category"
        :category-name="category.Name"
        :category-slug="category.Slug"
        :show-icon="false"
      />
      <i class="from-our-directory__delimiter" />
    </li>
  </ul>
</template>
<script>
import { PROP_TYPES, propValidator } from '@/utils/validators'
import { serverCacheKey } from '@/utils/mixins/serverCacheKey'
import AFmDirCategory from 'shared/AFmDirCategory'

export default {
  name: 'CategoryList',
  mixins: [serverCacheKey],
  components: { AFmDirCategory },
  props: {
    categories: propValidator([PROP_TYPES.ARRAY])
  }
}
</script>

<style scoped lang="scss">
@import '~assets/scss/local/mixins/grid';
@import '~assets/scss/global/mixins/figures';

.from-our-directory__category-list {
  $grid-setup: (
    $columnSetup: (
      $above-desktop-xl: repeat(3, minmax(0, 100%)),
      $mobile: repeat(2, minmax(0, 100%))
    ),
    $rowSetup: (
      $above-desktop-xl: repeat(3, minmax(0, auto))
    ),
    $columnGapSetup: (
      $above-desktop-xl: 10px
    ),
    $rowGapSetup: (
      $above-desktop-xl: 12px,
      $desktop-md: 8px,
      $tablet: 12px
    )
  );

  @include gridBuilder($grid-setup);

  .from-our-directory__category-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .from-our-directory__category {
    /deep/ .fm-dir-category__name {
      $font-size: (
        $above-desktop-xl: 15px,
        $desktop-lg: 14px,
        $desktop-md: 12px,
        $tablet: 16px,
        $mobile: 14px
      );

      $line-height: (
        $above-desktop-xl: 18px,
        $desktop-md: 15px,
        $tablet: 18px,
        $mobile: 16px
      );

      @include setStyleByBreakpoint('font-size', $font-size);
      @include setStyleByBreakpoint('line-height', $line-height);

      word-break: break-word;
      margin: 0;
    }
  }

  .from-our-directory__delimiter {
    @include delimiter(#d6d3d3, $mt: 13px);

    $margin-top: (
      $desktop-md: 8px,
      $tablet: 13px
    );

    @include setStyleByBreakpoint('margin-top', $margin-top);
  }
}
</style>
