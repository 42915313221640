<template>
  <a-link :to="generateFmDirUrl(categorySlug)" class="fm-dir-category__wrapper">
    <div v-if="showIcon" class="fm-dir-category__icon">
      <a-icon :icon="ICON.CATEGORY" :width="14" :height="14" />
    </div>
    <div class="fm-dir-category__name">{{ categoryName }}</div>
  </a-link>
</template>

<script>
import mixins from '@/utils/mixins'
import { propValidator, PROP_TYPES } from '@/utils/validators'
import AIcon, { ICON } from 'shared/AIcon'

export default {
  name: 'ACategory',
  components: { AIcon },
  mixins: [mixins.urlGenerators],
  props: {
    categoryName: propValidator([PROP_TYPES.STRING]),
    categorySlug: propValidator([PROP_TYPES.STRING]),
    showIcon: propValidator([PROP_TYPES.BOOLEAN], false, true)
  },
  data() {
    return { ICON }
  }
}
</script>

<style lang="scss" scoped>
.fm-dir-category__wrapper {
  display: flex;
  align-items: center;
  transition: none;

  &:hover {
    /deep/ .fm-dir-category__icon svg {
      fill: $c--main;
    }

    .fm-dir-category__name {
      color: $c--main;
    }
  }

  .fm-dir-category__icon {
    width: 18px;
    height: 18px;
    flex-shrink: 0;
  }

  .fm-dir-category__name {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    margin: 0 0 4px 2px;
  }
}
</style>
